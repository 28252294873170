import React from 'react';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Features from './components/Features';
import Contact from './components/Contact';
import Footer from './components/Footer';
import FooterLinks from './components/FooterLinks';
import Aux from './hoc/Aux_';
import { ReactComponent as MyLogo } from './assets/animated.svg';
import { ReactComponent as MyLogoNav } from './assets/logo.svg';
import { withTranslation } from 'react-i18next';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({ isLoading: false}), 2000)

  }
  section() {
    return (
        <section className="section section-lg height-120vh" id="home" >
                    <div id="bgg" className="bg-overlay">
                        <div className="m_section-header " data-component="SectionHeader" data-component-id="8eaf008e20">
                                 <video autoPlay playsInline muted loop className='m_section-header__background d-none d-md-block'>
                                        <source src="../video/home.mp4"></source>
                                </video>
                                <video autoPlay playsInline muted loop className='m_section-header__background d-md-none'>
                                        <source src="../video/home-mobile.mp4"></source>
                                </video>
                            
                        </div>
                        <p className='home-subtitle'>
                        <MyLogoNav height="20vh" style={{'padding':'10px'}} fill="black"/>
                        </p>
                    </div>
        </section>
    )
  }

  render() { 
  	return (
        this.state.isLoading ? 
        <Aux>
            <div className='logo-container'>
                <MyLogo className="animate-logo animate-svg"></MyLogo>
            </div>
        </Aux> :
        <Aux>
                {/* Navbar Component*/}
                <Navbar />
                { this.section() }
                <section id="about">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 mt-5">
                        <h1 className="section-title text-center">{this.props.t('about')}</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center font-secondary padding-t-30"></p>
                    </div>
                </div>
                    <div className="m_text-image">
                        <div className="m_text-image__inner" data-component="TextImage" data-component-id="472eb12289">
                            <div className="m_text-image__image ">
                                <img className="img-desktop" src={require('./assets/header.jpg')} alt=""/>
                            </div>
                            <div className="m_text-image__content">
                                <div className="content-inner">
                                    <h2 style={{'height':'15px'}}></h2>
                                    <div className="wysiwyg">
                                        <p style={{marginBottom: '2rem'}}>{this.props.t(`desc`)}</p>
                                        <p>{this.props.t('desc2')}</p>
                                    </div>                      
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Features Component*/}
                <Features />

                <section className='section'>
                    <h1 className='title-midsection gs_reveal'>{this.props.t("mission")}</h1>
                </section>
                {/* Services Component*/}
                <Services />

                {/* Contact Component*/}
                <Contact />

                
                {/* Footer Component*/}
                <Footer />

                {/* FooterLinks Component*/}
                <FooterLinks />
        </Aux>
  	);
  }
}

export default withTranslation()(Home);