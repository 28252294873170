import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/Lottie/Team.json'
import animationData2 from '../assets/Lottie/Handshake.json'
import animationData3 from '../assets/Lottie/Network.json'
import animationData4 from '../assets/Lottie/Results.json'

import { withTranslation } from 'react-i18next';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: animationData3,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: animationData4,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

class Services extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
  	return (
        <section className="section pt-5" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">{this.props.t('valueTitle')}</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center font-secondary padding-t-30">{this.props.t('value')}</p>
                    </div>
                </div>
                <div className="row margin-t-30 justify-content-center">
                    <div className="col-lg-4 margin-t-20 gs_reveal gs_reveal_fromLeft">
                        <div className="services-box text-center hover-effect">
                            {/* <i className="pe-7s-diamond text-custom"></i> */}
                            <Lottie options={defaultOptions} height={50} width={50}/>
                            <h4 className="padding-t-15">{this.props.t('agilityTitle')}</h4>
                            <p className="padding-t-15 text-muted">{this.props.t('agility')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20 gs_reveal gs_reveal_fromRight">
                        <div className="services-box text-center hover-effect">
                            <Lottie options={defaultOptions2} height={50} width={50}/>
                            <h4 className="padding-t-15">{this.props.t('confianceTitle')}</h4>
                            <p className="padding-t-15 text-muted">{this.props.t('confiance')}</p>
                        </div>
                    </div>
                    
                </div>
                <div className="row margin-t-30 justify-content-center">
                    <div className="col-lg-4 margin-t-20 gs_reveal gs_reveal_fromLeft">
                        <div className="services-box text-center hover-effect">
                            <Lottie options={defaultOptions3} height={50} width={50}/>
                            <h4 className="padding-t-15">{this.props.t('engagementTitle')}</h4>
                            <p className="padding-t-15 text-muted">{this.props.t('engagement')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20 gs_reveal gs_reveal_fromRight">
                        <div className="services-box text-center hover-effect">
                            <Lottie options={defaultOptions4} height={50} width={50}/>
                            <h4 className="padding-t-15">{this.props.t('resMarketingTitle')}</h4>
                            <p className="padding-t-15 text-muted">{this.props.t('resMarketing')}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
  	);
  }
}
export default  withTranslation()(Services);