import React from 'react';
import { createRoot } from 'react-dom/client';
import Home from './Home';
import Confidentiality from './components/Confidentiality';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './i18n';

class Root extends React.Component {
  render() {
  	return(
  		<BrowserRouter basename={'/'} >
		  	<Switch>
			  <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
			  <Route exact path={`${process.env.PUBLIC_URL}/confidentiality`} component={Confidentiality}/>
			</Switch>
		</BrowserRouter>
  	);
  }
 }
const root = createRoot(document.getElementById('root'))
root.render(<Root />);
registerServiceWorker();
