import React from 'react';
import { withTranslation } from 'react-i18next';

class Contact extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
  	return (
      <section className="section" id="contact">
        <div className="container footer2">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <h1 className="section-title text-center">{this.props.t('contactUs')}</h1>
                  <div className="section-title-border margin-t-20"></div>
                  <div className="row" style={{'marginTop': '2rem', 'marginBottom': '2rem'}}>
                      <div className="col-lg-4" style={{'textAlign': 'center'}}>
                          <p className="link-footer margin-b-0 contact-title">{this.props.t("follow")} <a target="_blank" href="https://www.linkedin.com/company/mercureconseil/">LinkedIn</a></p>
                      </div>
                      <div className="col-lg-4" style={{'textAlign': 'center'}}>
                          <a href='tel:+15147005550' className="margin-b-0 contact-title"><i className="pe-7s-call"></i> &nbsp;+1 514-700-5550</a>
                      </div>
                      <div className="col-lg-4" style={{'textAlign': 'center'}}>
                          <a href="mailto:info@mercureconseil.ca" className="contact-title"><i className="pe-7s-mail-open"></i>&nbsp; info@mercureconseil.ca</a>
                      </div>
                </div>
                <div className="row" style={{'marginTop': '2rem', 'marginBottom': '2rem'}}>
                      <div className="col-lg-4" style={{'textAlign': 'center'}}>
                          
                      </div>
                      <div className="col-lg-4" style={{'textAlign': 'center'}}>
                          <p style={{fontSize: '14px', lineHeight: '1.5'}} className="link-footer margin-b-0 contact-title">{this.props.t("protectionTitle")}</p>
                          <p style={{fontSize: '14px', lineHeight: '1.5'}} className="link-footer margin-b-0 contact-title">Wenyu Jiang</p>
                          <p style={{fontSize: '14px', lineHeight: '1.5'}} className="link-footer margin-b-0 contact-title">{this.props.t("financeTitle")}</p>
                          <p style={{fontSize: '14px', lineHeight: '1.5'}} className="link-footer margin-b-0 contact-title">wjiang@mercureconseil.ca </p>
                      </div>
                      <div className="col-lg-4" style={{'textAlign': 'center'}}>
                          {/* <a href="mailto:info@mercureconseil.ca" className="contact-title"><i className="pe-7s-mail-open"></i>&nbsp; info@mercureconseil.ca</a> */}
                      </div>
                </div>
              </div>
            </div>
        </div>
      </section> 
  	);
  }
}
export default withTranslation()(Contact);