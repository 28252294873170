import React from 'react';
import { withTranslation } from 'react-i18next';

class FooterLinks extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
  	return (
        <div className="footer-alt">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="pull-none" style={{'textAlign':'center'}}>
                        <p className="copy-rights text-muted" style={{'fontSize':'14px'}}><a target='_blank' href="/confidentiality">{this.props.t('confidLink')}</a></p>
                        <p className="copy-rights text-muted" style={{'fontSize':'14px'}}>{(new Date().getFullYear())} © Mercure Conseil par <a href="https://cinestudio.pro">Cinestudio</a></p>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
  	);
  }
}
export default withTranslation()(FooterLinks);