import React from 'react';
import { ReactComponent as MyLogo } from '../assets/logo.svg';
import { withTranslation } from 'react-i18next';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
      }
    scrollTo = (id) => {
        if(document.getElementById(id)){
            document.getElementById(id).scrollIntoView();
        }
    }

  changeLanguage = () => {
    this.props.i18n.resolvedLanguage == "en" ? this.props.i18n.changeLanguage('fr') : this.props.i18n.changeLanguage('en') 
  }
  render() {
  	return (
        <nav className="autohide navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
            <div className="container">
                <div className="navbar-brand logo text-uppercase" onClick={() => this.scrollTo('home')}>
                    <MyLogo width="15rem" fill="black"/>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="mdi mdi-menu"></i>
                </button>
                <div className="collapse navbar-collapse" style={{'justifyContent':'flex-end'}} id="navbarCollapse">
                    <ul className="navbar-nav navbar-center" id="mySidenav">
                        <li className="nav-item active">
                            <a href="#home" className="nav-link">{this.props.t('accueil')}</a>
                        </li>
                        <li className="nav-item">
                            <a href="#about" className="nav-link">{this.props.t('about')}</a>
                        </li>
                        <li className="nav-item">
                            <a href="#features" className="nav-link">{this.props.t('expertise')}</a>
                        </li>
                        <li className="nav-item">
                            <a href="#services" className="nav-link">{this.props.t('valeur')}</a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" className="nav-link">Contact</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" style={{"cursor":"pointer"}} onClick={() => this.changeLanguage()}>{this.props.i18n.resolvedLanguage == "en" ? "FR" : "EN"}</a>
                        </li>
                    </ul>
                    
                </div>
            </div>
        </nav>
  	);
  }
}

export default  withTranslation()(Navbar);