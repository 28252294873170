import React from 'react';

class Confidentiality extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
      return (
        <div className='centered-text-politic'>
          <b>English will follow</b><br/><br/>
            <b>Politique de protection des données personnelles de MERCURE Conseil</b><br/>
Chez MERCURE Conseil, nous accordons une importance primordiale à la protection des données
personnelles de nos employés, clients, partenaires et toute personne concernée par nos activités. Notre
politique vise à garantir que toutes les données personnelles sont traitées de manière transparente,
légitime et sécurisée.<br/><br/>

<b>Traitement des données personnelles</b><br/>
Chez MERCURE Conseil, le traitement des données personnelles est essentiel pour la réalisation de
notre mission d&#39;intérêt public. Nous collectons et traitons des données personnelles pour diverses
finalités, notamment la gestion des ressources humaines, les processus de recrutement, les achats, la
gestion de notre site web et la participation à des réunions ou événements. Les données personnelles
peuvent également être utilisées pour la collecte d&#39;éléments probants afin de soutenir le
développement de politiques et de programmes.<br/><br/>

<b>Règles de protection des données à MERCURE Conseil</b><br/>
Conformément aux règles de protection des données en vigueur à MERCURE Conseil, toutes les données
personnelles doivent être traitées de manière transparente, légitime et dans le cadre de notre mission
et de nos activités. Les données collectées doivent être appropriées, pertinentes, mises à jour
régulièrement et conservées uniquement pendant la durée nécessaire à des fins spécifiques. Les règles
imposent également que des mesures techniques et organisationnelles appropriées soient en place, en
particulier lorsqu&#39;il s&#39;agit de catégories spéciales de données ou de traitements à haut risque.
Chez MERCURE Conseil, la protection des données est intégrée par défaut et dès la conception de nos
activités. Nous évaluons les risques de sécurité associés au traitement des données et mettons en
œuvre des mesures proportionnées pour les atténuer. En cas de violation de données personnelles,
nous avons l&#39;obligation de notifier les personnes concernées. Tout transfert de données personnelles à
des entités extérieures à MERCURE Conseil ne peut avoir lieu qu&#39;avec des garanties appropriées pour
protéger les droits des personnes concernées.<br/><br/>

<b>Droits individuels</b><br/>
Les personnes concernées par le traitement des données personnelles à MERCURE Conseil ont des
droits clairement énoncés. Cela inclut le droit à l&#39;information, l&#39;accès, la rectification, l&#39;effacement et
l&#39;opposition. Toutes les réclamations et demandes liées à ces droits peuvent être directement soumises
à nos responsables.<br/><br/>

<b>Mise en œuvre et contrôle</b><br/>
MERCURE Conseil a mis en place un programme de gestion de la vie privée pour garantir la mise en
œuvre de nos règles de protection des données. Notre Délégué à la protection des données est responsable de la conformité et du respect de ces règles. Il est également disponible pour fournir des
conseils et des informations aux membres de notre personnel, ainsi qu&#39;au public.
<br/><br/><br/>
<b>English Version</b><br/>
<b>Data Protection Policy of MERCURE Conseil</b><br/>
At MERCURE Conseil, we place the utmost importance on the protection of personal data of our employees, clients, partners, and anyone affected by our activities. Our policy aims to ensure that all personal data is processed in a transparent, legitimate, and secure manner.<br/><br/>

<b>Processing of Personal Data</b><br/>
At MERCURE Conseil, the processing of personal data is essential for the fulfillment of our public interest mission. We collect and process personal data for various purposes, including human resource management, recruitment processes, procurement, management of our website, and participation in meetings or events. Personal data may also be used for the collection of evidence to support the development of policies and programs.<br/><br/>

<b>Data Protection Rules at MERCURE Conseil</b><br/>
In accordance with the data protection rules in force at MERCURE Conseil, all personal data must be processed in a transparent, legitimate manner and within the scope of our mission and activities. The collected data must be appropriate, relevant, regularly updated, and kept only for the duration necessary for specific purposes. The rules also require that appropriate technical and organizational measures be in place, particularly when it comes to special categories of data or high-risk processing. At MERCURE Conseil, data protection is integrated by default and from the design of our activities. We assess the security risks associated with data processing and implement proportionate measures to mitigate them. In the event of a personal data breach, we are obligated to notify the affected individuals. Any transfer of personal data to entities external to MERCURE Conseil can only take place with appropriate guarantees to protect the rights of the affected individuals.<br/><br/>

<b>Individual Rights</b><br/>
Individuals affected by the processing of personal data at MERCURE Conseil have clearly stated rights. This includes the right to information, access, rectification, erasure, and objection. All claims and requests related to these rights can be directly submitted to our responsible parties.<br/><br/>

<b>Implementation and Control</b><br/>
MERCURE Conseil has put in place a privacy management program to ensure the implementation of our data protection rules. Our Data Protection Officer is responsible for compliance and adherence to these rules. They are also available to provide advice and information to members of our staff, as well as to the public.<br/>
        </div>
      );
    }
  }
export default Confidentiality;
