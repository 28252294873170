import React from 'react';

class Card extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
      return (
        <div className={this.props.className + " flip-box"}>
            <div className="flip-box_front" style={{"backgroundImage":this.props.image}}>
                <div className="flip-box_text">
                  <div className='flip-box_icon'>
                    {this.props.icon}
                  </div>
                  <h3>{this.props.title}</h3>
                </div>
            </div>
            <div className="flip-box_back">
                <div className="flip-box_text">
                  <h3>{this.props.title}</h3>
                  {this.props.text}
                </div>
            </div>
        </div>
      );
    }
  }
export default Card;
