import React from 'react';
import { withTranslation } from 'react-i18next';

class Footer extends React.Component {
    constructor(props){
        super(props)
    }
  render() {
  	return (
         <footer className="footer">
            <div className="container" style={{fontFamily:'Georgia'}}>
            <div className="row d-lg-none">
                <div className="col-lg-8 offset-lg-2">
                      <div className="row py-3">
                        <div className='col-md-12 text-muted text-contact'><p><a className="rounded" target="_blank" href='https://www.google.com/maps/place/1188+Av.+Union+Bureau+510,+Montr%C3%A9al,+QC+H3B+3A7/@45.5027937,-73.5676895,17'>{this.props.t('montreal')}</a><br></br>1188, avenue Union<br></br>Bureau 510<br></br>{this.props.t('montreal')}, {this.props.t('quebec')}<br></br>H3b 0E5</p></div>
                      </div>
                      <div className="row">
                        <div className='col-md-12 text-muted text-contact'><p><a className="rounded " target="_blank" href='https://www.google.com/maps/place/81+Rue+Saint-Louis+Bureau+1,+Qu%C3%A9bec,+QC+G1R+3Z4/@46.8101818,-71.2119319,17z'>{this.props.t('quebec')}</a><br></br>81, rue Saint-Louis<br></br>Bureau 1<br></br>{this.props.t('quebec')}, {this.props.t('quebec')}<br></br>G1R 3Z4</p></div>
                      </div>
                </div>
            </div>    
            <div className="row d-none d-lg-block">
              <div className="footer_leftcol">
                <div style={{"marginRight":"-15px","marginLeft":"-15px"}}>
                  <div className="footer_inner">
                    <div className="widget_text infobox wow fadeInLeftBig" style={{"visibility": "visible", "animationName": "fadeInLeftBig"}}>
                      <a className="rounded h2" target="_blank" href='https://www.google.com/maps/place/1188+Av.+Union+Bureau+510,+Montr%C3%A9al,+QC+H3B+3A7/@45.5027937,-73.5676895,17'>
                      {this.props.t('montreal')}
                      </a>
                        <div className="textwidget custom-html-widget">
                        1188, avenue Union<br></br>Bureau 510<br></br>{this.props.t('montreal')}, {this.props.t('quebec')}<br></br>H3B 0E5
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer_rightcol">
                <div style={{"marginRight":"-15px","marginLeft":"-15px"}}>
                  <div className="footer_inner">
                    <div className="widget_text infobox wow fadeInRightBig" style={{"visibility": "visible", "animationName": "fadeInRightBig"}}>
                      <a className="rounded h2" target="_blank" href='https://www.google.com/maps/place/81+Rue+Saint-Louis+Bureau+1,+Qu%C3%A9bec,+QC+G1R+3Z4/@46.8101818,-71.2119319,17z'>
                      {this.props.t('quebec')}
                      </a>
                      <div className="textwidget custom-html-widget">
                        81, rue Saint-Louis<br></br>Bureau 1<br></br>{this.props.t('quebec')}, {this.props.t('quebec')}<br></br>G1R 3Z4
                      </div>
                    </div>
                  </div>
                </div>
              </div>
			      </div>
            </div>
        </footer>
  	);
  }
}
export default withTranslation()(Footer);