import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from './Card'
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ShareIcon from '@mui/icons-material/Share';
import { useTranslation } from 'react-i18next';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

export default function Features() {
     const [expanded, setExpanded] = React.useState('panel1');
     const { t, i18n } = useTranslation();

  const handleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  	return (
        <section className="section-2 bg-light" id="features" >
            <div className='d-none d-lg-block'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 mt-5">
                        <h1 className="section-title text-center">{t('pilarTitle')}</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center font-secondary padding-t-30">{t('pilarDesc')}</p>
                    </div>
                </div>
                <div className="row margin-t-30 justify-content-center">
                    <div className="col-lg-5 margin-t-20">
                        <Card
                            className="gs_reveal gs_reveal_fromLeft"
                            image={`url("../images/piliers/rel2.webp")`}
                            icon={<AssuredWorkloadIcon/>}
                            title={t("prTitle")} 
                            text={t("pr")}>   
                        </Card>
                    </div>
                    <div className="col-lg-5 margin-t-20">
                        <Card
                            className="gs_reveal gs_reveal_fromRight"
                            image={`url("../images/piliers/comm.webp")`}
                            icon={<SpeakerNotesIcon/>}
                            title={t("comTitle")}
                            text={t("com")}
                            >
                        </Card>
                    </div>
                    
                </div>
                <div className="row margin-t-30 justify-content-center">
                    <div className="col-lg-5 margin-t-20">
                        <Card
                            className="gs_reveal gs_reveal_fromLeft"
                            image={`url("../images/piliers/relation.webp")`}
                            icon={<ShareIcon/>}
                            title={t("investorTitle")}
                            text={t("investor")}
                            >
                        </Card>
                    </div>
                    <div className="col-lg-5 margin-t-20">
                        <Card
                            className="gs_reveal gs_reveal_fromRight"
                            image={`url("../images/piliers/recherche.webp")`}
                            icon={<TravelExploreIcon/>}
                            title={t("researchTitle")}
                            text={t("research")}
                            
                            >
                        </Card>
                    </div>
                    
                </div>
            </div>
                    
            </div>
            <div className="row d-lg-none">
                    <div className="col-lg-8 offset-lg-2 mt-5">
                        <h1 className="section-title text-center">{t('pilarTitle')}</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center font-secondary padding-t-30">{t('pilarDesc')}</p>
                    </div>
                </div>
            <div className='d-lg-none bg-home' style={{'height':'max(100vh, 815px)'}}>

            <Accordion className='mobile-accordion' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='expertise-title'
                    >
                        <Typography className='expertise-title'>{t("prTitle")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='expertise-paragraph'>
                        <Typography>{t("pr")}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='mobile-accordion' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='expertise-title'
                    >
                        <Typography className='expertise-title'>{t("comTitle")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='expertise-paragraph'>
                        <Typography>
                        {t("com")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='mobile-accordion' expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='expertise-title'
                    >
                        <Typography className='expertise-title'>{t("investorTitle")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='expertise-paragraph'>
                        <Typography>
                        {t("investor")}​
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='mobile-accordion' expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='expertise-title'
                    >
                        <Typography className='expertise-title'>{t("researchTitle")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='expertise-paragraph'>
                        <Typography>
                            {t("research")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </section>
  	);
  }